@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeOutLeft {
    from {
        opacity: 1;
        transform: translateX(-10px);
    }
    to {
        opacity: 0;
        transform: translateX(-100px);
    }
}

@keyframes fadeOutDown {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(20px);
    }
}
@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

@keyframes fadeOutUp {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-20px);
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

/* Toast 알림의 z-index를 가장 앞으로 설정 */
.chakra-toast {
    z-index: 9999999 !important;  /* 원하는 z-index 값 */
  }


.loading-text {
    background: linear-gradient(90deg, rgba(150, 150, 150, 0.7) 25%, rgba(0, 0, 0, 1) 50%, rgba(150, 150, 150, 0.7) 75%);
    background-size: 200% 100%;
    animation: loading 3s infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}